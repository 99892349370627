<template>
  <header>
    <h1>neustore.com.br</h1>
  </header>
</template>

<style scoped>
  header {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: .1rem;
    align-items: center;
    justify-content: center;
  }
</style>